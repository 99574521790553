<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{name: 'dashboard'}">Dashboard</router-link></li>
        <li class="breadcrumb-item active">Schedules</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">Schedules</h2>
          </div>
          <div class="col text-end">
          <router-link v-if="this.$store.state.permittedActions.includes(41)"
            :to="{ name: 'add-schedule' }"
            class="btn btn-outline-primary"
            >Add</router-link
          >
          </div>
      </div>
        <div class="t-layout">
          <div class="row">
              <div class="col-md-12">
                <div v-if="this.$store.state.loader">
                    <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
                </div>
                <div v-else class="card">
                <div class="card-header">
                  <flash-message class="myCustomClass"></flash-message>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search data"
                      ></b-form-input>
                    </div>
                    <div class="col-md-9 tx-right">
                      <div class="page-number-input custom-box">
                        <label>Go to Page:</label>
                        <b-form-input
                        class="page-number"
                        v-model="currentPage"
                        type="number"
                        min="1"
                        :max="schedulesData.length > 0 ? Math.ceil(schedulesData.length/perPage) : 1"
                      ></b-form-input>
                      </div>
                      <div class="pagination-wrapper custom-box">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="schedulesData.length"
                          :per-page="perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                  <b-table
                    class="table table-custom"
                    :items="schedulesData"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-desc.sync="sortDesc"
                    :sort-by.sync="sortBy"
                    show-empty
                    :filter="filter"
                    sticky-header
                  >
                  <template #head()="{ label, field: { key, sortable }}">
                    {{ label }}
                    <template v-if="sortable">
                      <template>
                        <b-img v-if="sortBy !== key" :src="sortIconNeutral" style="width: 0.65rem; height: 1rem;"></b-img>
                        <span title="Sort By ascending" v-else-if="sortDesc"><b-img :src="sortIconAsc" style="width: 0.65rem; height: 1rem;"></b-img></span>
                        <span v-else title="Sort By descending" ><b-img :src="sortIconDesc" style="width: 0.65rem; height: 1rem;"></b-img></span>


                      </template>
                    </template>
                  </template>
                  <template v-slot:cell(time)="data">
                      {{ data.item.intime +' - '+ data.item.outime}}
                  </template>
                  <template v-slot:cell(rest_days)="data">
                      {{ data.item.restday }}
                  </template>
                  <template v-slot:cell(from)="data">
                      {{ data.item.datefrom }}
                  </template>
                  <template v-slot:cell(to)="data">
                      {{ data.item.dateto }}
                  </template>
                  <template v-slot:cell(status)="data">
                      {{ data.item.archive == 0 ? 'Present' : 'Previous' }}
                  </template>
                  <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                          <div class="d-flex justify-content-md-around">
                              <span  v-if="$store.state.permittedActions.includes(42)"><a class="button button1" v-if="data.item.archive == 0" title="Edit" @click.prevent="setPageNumber(data.item.id)"><i class="icon-pencil"></i></a></span>
                              <a class="button button1" v-if="$store.state.permittedActions.includes(43)" title="Delete"  @click.prevent="deleteSchedule(data.item.id)"><i class="icon-bin"></i></a>
                          </div>
                      </div>
                  </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';

export default {
    data() {
        return {
            currentPage: 1,
            perPage: 15,
            filter: null,
            sortIconNeutral: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconDesc: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortIconAsc: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
            sortBy: null,
            sortDesc: false,
            fields: [
                {
                    key: 'employee',
                    sortable: true,
                    tdAttr: { 'custom-label'  : "Employee:"}
                },
                {
                    key: 'time',
                    label: 'Time (Start-Off)',
                    tdAttr: { 'custom-label'  : "Time (Start-Off):"}
                },
                {
                    key: 'hours',
                    tdAttr: { 'custom-label'  : "Hours:"}
                },
                {
                    key: 'rest_days',
                    tdAttr: { 'custom-label'  : "Rest Days:"}
                },
                {
                    key: 'from',
                    label: 'From (Date)',
                    tdAttr: { 'custom-label'  : "From (Date):"}
                },
                {
                    key: 'to',
                    label: 'To (Date)',
                    tdAttr: { 'custom-label'  : "To (Date):"}
                },
                {
                    key: 'status',
                    tdAttr: { 'custom-label'  : "Status:"}
                },
                {
                    key: 'action',
                    tdAttr: { 'custom-label'  : "Action:"}
                }
            ]
        }
    },
    computed: {
        schedulesData(){
            return this.$store.state.schedulesData.schedules;
        }
    },
    mounted() {
        this.$store.dispatch('switchLoader',true);
        this.$store.dispatch('loadSchedulesdata');
        if(localStorage.getItem('page_number')){
          this.currentPage = localStorage.getItem('page_number');
          localStorage.removeItem('page_number');
        }
    },
    methods:{
      
      setPageNumber(id){
        localStorage.setItem('page_number',this.currentPage);
        this.$router.push({ name: 'schedule-update', params: { id: id }}); 
        
      },
      formatTime(time){
        return moment(time).format('hh:mm A');
      },
      deleteSchedule(id){
        if(confirm("Are you sure, you want to delete this schedule ?")){
          axios.get('/schedules/delete/'+id)
            .then(({data}) => {
              if(data.status == 'success')
              this.flashMessage.success({
              message: 'Schedule deleted successfully',
              time: 3000
            });
            this.$store.dispatch('loadSchedulesdata');
            })
        }
      },
    }
}
</script>
